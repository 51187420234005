import Vue from 'vue';
import pinia from '@/stores';
import router from '@/router';

import '@/loaders/filters';
import '@/loaders/plugins';
import '@/loaders/components';
import '@/loaders/injections';

import '@/validations';

import App from '@/App.vue';

if (process.env.NODE_ENV === 'development') Vue.config.productionTip = true;
if (process.env.NODE_ENV === 'production') Vue.config.productionTip = false;
if (process.env.VUE_APP_ENV === 'prod') Vue.config.devtools = false;

// @ts-expect-error Fix
Vue.use(pinia);

new Vue({
  router,
  pinia,
  render: (h) => h(App),
}).$mount('#app');
