import axios from '@/services/axios';

const api = {
  auth: {
    async login(payload) {
      return axios.post('auth/login', payload);
    },
    async register(payload) {
      return axios.post('auth/signup', payload);
    },
    async registerVerificationRequest(payload) {
      return axios.post('auth/verify-email', payload);
    },
    async googleAuth(payload) {
      return axios.post('auth/google/url', payload);
    },
    async resetPasswordRequest(payload) {
      return axios.post('auth/password-reset/request', payload);
    },
    async resetPasswordVerifyCode(payload) {
      return axios.post('auth/password-reset/verify-code', payload);
    },
    async resetPasswordChangePassword(payload) {
      return axios.post('auth/password-reset/change-password', payload);
    }
  },
  user: {
    me(coupon) {
      /* eslint-disable */
      return axios.get('users/me', { params: { promoCode: coupon === 'BM2024' ? 'BF2024' : coupon || localStorage.getItem('promo-code') === 'BM2024' ? 'BF2024' : localStorage.getItem('promo-code') } });
    },
    redeemCoupon(payload) {
      return axios.post('users/redeem-coupon', payload);
    },
    updateUser(payload) {
      return axios.put('users/me', payload);
    },
    updateSettings(payload) {
      return axios.post('users/settings', payload);
    },
    changePassword(payload) {
      return axios.post('users/change-password', payload);
    },
    customRequest(payload) {
      return axios.post('credits/request', payload);
    },
    applyTrialPromo() {
      return axios.get('users/trial-promo');
    }
  },
  billing: {
    subscription: {
      get() {
        return axios.get('subscriptions');
      },
      update(payload) {
        return axios.post('subscriptions/update', payload);
      },
      session(payload) {
        return axios.post('subscriptions/session', payload);
      },
      buyCredits(payload) {
        return axios.post('subscriptions/buy-creditsv2', payload);
      },
      activatePlan() {
        return axios.post('subscriptions/pay-now');
      },
      cancel(payload) {
        return axios.post('subscriptions/cancel', payload);
      }
    },
    paymentMethods: {
      getAll() {
        return axios.get('payment-methods');
      },
      create(payload) {
        return axios.post('payment-methods/card-checkout-session', payload);
      },
      setDefault(id) {
        return axios.post(`payment-methods/${id}/default`);
      },
      delete(id) {
        return axios.delete(`payment-methods/${id}`);
      },
    },
    invoices: {
      getAll() {
        return axios.get('invoices');
      },
      getUpcomingOverages() {
        return axios.get('invoices/upcoming/overages');
      },
      getUpcomingPlan() {
        return axios.get('invoices/upcoming/plan');
      }
    },
    plans: {
      getAll() {
        return axios.get('plans');
      },
    }
  },
  usage: {
    get() {
      return axios.get('usage/get');
    }
  },
  dashboard: {
    getStats({ startDate, endDate, timezone }) {
      return axios.get('dashboard/get', { params: { startDate, endDate, timezone } });
    },
  },
  reporting: {
    getGraphs({
      startDate, endDate, timezone, landerIds
    }) {
      return axios.get('reporting/get', {
        params: {
          startDate, endDate, timezone, landerIds
        }
      });
    }
  },
  folders: {
    getAll() {
      return axios.get('folders/get');
    },
    create(payload) {
      return axios.post('folders/create', payload);
    },
    update(id, payload) {
      return axios.post(`folders/${id}/update`, payload);
    },
    delete(id) {
      return axios.delete(`folders/${id}/delete`);
    },
  },
  landingPages: {
    getAll() {
      return axios.get('landers/get');
    },
    get(id) {
      return axios.get(`landers/${id}/get`);
    },
    getAnalytics({ startDate, endDate, timezone }) {
      return axios.get('landers/analytics/get', { params: { startDate, endDate, timezone } });
    },
    search({
      page, limit, q, folderId, websiteId
    }) {
      return axios.get('landers/search', {
        params: {
          page, limit, q, folderId, websiteId
        }
      });
    },
    create(payload, type) {
      return axios.post(`landers/create${type ? `/${type}` : ''}`, payload);
    },
    update(id, payload) {
      return axios.put(`landers/${id}/update`, payload);
    },
    clone(id) {
      return axios.post(`landers/${id}/clone`);
    },
    publish(id, payload) {
      return axios.post(`landers/${id}/publish`, payload);
    },
    unpublish(id) {
      return axios.post(`landers/${id}/unpublish`);
    },
    download(id) {
      return axios.get(`landers/${id}/download`, { responseType: 'blob' });
    },
    moveToFolder(payload) {
      return axios.post('landers/move-to-folder', payload);
    },
    removeFromFolder(id) {
      return axios.post(`landers/${id}/remove-from-folder`);
    },
    addToTracker(id, integrationId, payload) {
      return axios.post(`landers/${id}/integrations/${integrationId}/publish`, payload);
    },
    delete(id) {
      return axios.delete(`landers/${id}/delete`);
    },
  },
  websites: {
    getAll() {
      return axios.get('websites/get');
    },
    get(id) {
      return axios.get(`websites/${id}/get`);
    },
    create(payload) {
      return axios.post('websites/create', payload);
    },
    update(id, payload) {
      return axios.post(`websites/${id}/update`, payload);
    },
    publish(id, payload) {
      return axios.post(`websites/${id}/publish`, payload);
    },
    unpublish(id) {
      return axios.post(`websites/${id}/unpublish`);
    },
    setMaster(id, landerId) {
      return axios.post(`websites/${id}/landers/${landerId}/setMaster`);
    },
    delete(id) {
      return axios.delete(`websites/${id}/delete`);
    },
  },
  templates: {
    getAll() {
      return axios.get('templates/get');
    },
    search({ page, limit, q }) {
      return axios.get('templates/search', { params: { page, limit, q } });
    },
  },
  lander: {
    analytics: {
      getStats(id, { startDate, endDate, timezone }) {
        return axios.get(`landers/${id}/analytics/stats/get`, { params: { startDate, endDate, timezone } });
      },
      getGraphs(id, { startDate, endDate, timezone }) {
        return axios.get(`landers/${id}/analytics/graphs/get`, { params: { startDate, endDate, timezone } });
      },
      getReports(id, { startDate, endDate, timezone }) {
        return axios.get(`landers/${id}/analytics/reports/get`, { params: { startDate, endDate, timezone } });
      },
    },
    variants: {
      getAll(id) {
        return axios.get(`landers/${id}/variants/get`);
      },
      enableAbTestMode(id) {
        return axios.post(`landers/${id}/variants/enable`);
      },
      disableAbTestMode(id) {
        return axios.post(`landers/${id}/variants/disable`);
      },
      setWeights(id, payload) {
        return axios.post(`landers/${id}/variants/setWeights`, payload);
      },
      create(id, payload) {
        return axios.post(`landers/${id}/variants/create`, payload);
      },
      update(id, variantId, payload) {
        return axios.put(`landers/${id}/variants/${variantId}/update`, payload);
      },
      uploadFavicon(id, variantId, payload) {
        return axios.post(`landers/${id}/variants/${variantId}/favicon/upload`, payload);
      },
      setMaster(id, variantId) {
        return axios.post(`landers/${id}/variants/${variantId}/setMaster`);
      },
      setIndex(id, variantId, payload) {
        return axios.post(`landers/${id}/variants/${variantId}/setIndex`, payload);
      },
      delete(id, variantId) {
        return axios.delete(`landers/${id}/variants/${variantId}/delete`);
      },
    },
    leads: {
      getAll(id, {
        variantId, startDate, endDate, timezone, page, limit, q, status
      }) {
        return axios.get(`landers/${id}/leads/get`, {
          params: {
            variantId, startDate, endDate, timezone, page, limit, q, status
          },
        });
      },
      sendToIntegrations(landerId, payload) {
        return axios.post(`landers/${landerId}/leads/export`, payload);
      },
    },
    integrations: {
      getIntegrationData(landerId, params) {
        return axios.get(`landers/${landerId}/integrations/getIntegrationData`, { params });
      },
      create(landerId, payload) {
        return axios.post(`landers/${landerId}/integrations/create`, payload);
      },
      update(landerId, integrationId, payload) {
        return axios.put(`landers/${landerId}/integrations/${integrationId}/update`, payload);
      },
      enableIntegration(landerId, integrationId) {
        return axios.post(`landers/${landerId}/integrations/${integrationId}/enable`);
      },
      disableIntegration(landerId, integrationId) {
        return axios.post(`landers/${landerId}/integrations/${integrationId}/disable`);
      },
      delete(landerId, integrationId) {
        return axios.delete(`landers/${landerId}/integrations/${integrationId}/delete`);
      },
    },
  },
  leads: {
    getAll({
      startDate, endDate, timezone, page, limit, q, landerIds, status
    }) {
      return axios.get('leads/get', {
        params: {
          startDate, endDate, timezone, page, limit, q, landerIds, status
        },
      });
    },
    getAllForExport({
      startDate, endDate, timezone, page, limit, q, landerIds, status
    }) {
      return axios.get('leads/getAll', {
        params: {
          startDate, endDate, timezone, page, limit, q, landerIds, status
        },
      });
    },
    update(payload) {
      return axios.put('leads/update', payload);
    },
    delete(payload) {
      return axios.post('leads/delete', payload);
    },
  },
  domains: {
    getAll() {
      return axios.get('domains/get');
    },
    check() {
      return axios.get('domains/check');
    },
    create(payload) {
      return axios.post('domains/create', payload);
    },
    delete(id) {
      return axios.delete(`domains/${id}/delete`);
    },
  },
  cloudflareAccounts: {
    getAll() {
      return axios.get('cloudflare/get');
    },
    create(payload) {
      return axios.post('cloudflare/create', payload);
    },
    update(id, payload) {
      return axios.put(`cloudflare/${id}/update`, payload);
    },
    delete(id) {
      return axios.delete(`cloudflare/${id}/delete`);
    },
  },
  website: {
    analytics: {
      getStats(id, { startDate, endDate, timezone }) {
        return axios.get(`websites/${id}/analytics/stats/get`, { params: { startDate, endDate, timezone } });
      },
      getGraphs(id, { startDate, endDate, timezone }) {
        return axios.get(`websites/${id}/analytics/graphs/get`, { params: { startDate, endDate, timezone } });
      },
      getReports(id, { startDate, endDate, timezone }) {
        return axios.get(`websites/${id}/analytics/reports/get`, { params: { startDate, endDate, timezone } });
      },
    },
  },
  editor: {
    lander: {
      get(id) {
        return axios.get(`editors/${id}/load`);
      },
      // preview(id, html) {
      //   return axios.post(`editor/preview/${id}`, { html });
      // },
      save(id, config) {
        return axios.post(`editors/${id}/save`, config);
      },
      // uploadFavicon(id, fd) {
      //   return axios.post(`editor/upload/${id}`, fd, { headers: { 'Content-Type': 'multipart/form-data' } });
      // },
      loadFile(id, fileName) {
        return axios.get(`editors/${id}/loadFile`, { params: { fileName } });
      },
      saveFile(id, payload) {
        return axios.post(`editors/${id}/saveFile`, payload);
      },
      addFile(id, payload) {
        return axios.post(`editors/${id}/uploadFile`, payload);
      },
      deleteFile(id, payload) {
        return axios.post(`editors/${id}/deleteFile`, payload);
      },
      renameFile(id, payload) {
        return axios.post(`editors/${id}/renameFile`, payload);
      },
      generateTextAi(id, payload) {
        return axios.post(`editors/${id}/generate`, payload);
      },
      generateImageAi(id, payload) {
        return axios.post(`editors/${id}/image/generate`, payload, {
          responseType: 'arraybuffer'
        });
      },
      aiBgRemovalAccess(payload) {
        return axios.post('editors/remove-bg/access', payload);
      },
      aiBgRemovalConsume(id) {
        return axios.get(`editors/${id}/remove-bg/consume`);
      },
      translate(id, payload) {
        return axios.post(`editors/${id}/translate`, payload);
      },
      getVersions(id) {
        return axios.get(`editors/${id}/versions/get`);
      },
      revertVersion(id, payload) {
        return axios.post(`editors/${id}/versions/revert`, payload);
      }
    },
    gallery: {
      get() {
        return axios.get('gallery/get');
      },
      add(payload) {
        return axios.post('gallery/upload', payload);
      },
      addFromUrl(payload) {
        return axios.post('gallery/url/upload', payload);
      },
      delete(id) {
        return axios.delete(`gallery/${id}/delete`);
      },
    },
    config: {
      get() {
        return axios.get('editors/config/get');
      },
    },
    admin: {
      getTemplate(id) {
        return axios.get(`admin/templates/${id}/load`);
      },
      saveTemplate(id, config) {
        return axios.post(`admin/templates/${id}/updateCode`, config);
      },
      loadFile(id, fileName) {
        return axios.get(`admin/templates/${id}/loadFile`, { params: { fileName } });
      },
      saveFile(id, payload) {
        return axios.post(`admin/templates/${id}/saveFile`, payload);
      },
    },
    icons: {
      getAll({
        page, limit, q, category = 'all'
      }) {
        return axios.get('editors/icons', {
          params: {
            page, limit, q, category
          },
        });
      },
      upload(payload) {
        return axios.post('editors/icons/upload', payload);
      },
      delete(id) {
        return axios.delete(`editors/icons/${id}/delete`);
      },
    }
  },
  integrations: {
    getAll() {
      return axios.get('integrations/get');
    },
    create(payload) {
      return axios.post('integrations/create', payload);
    },
    delete(id) {
      return axios.delete(`integrations/${id}/delete`);
    },
  },
  logs: {
    integrations: {
      getAll({
        startDate, endDate, timezone, page, limit, q, landerIds, type
      }) {
        return axios.get('logs/landerIntegrations/get', {
          params: {
            startDate, endDate, timezone, page, limit, q, landerIds, type
          },
        });
      },
    },
    credits: {
      getAll() {
        return axios.get('credits/get');
      },
    }
  },
  feedbacks: {
    report(payload) {
      return axios.post('feedbacks/create', payload);
    }
  },
  onboarding: {
    set(payload) {
      return axios.patch('users/onboarding', payload);
    }
  }
};

const apiProxy = (obj, path = '') => {
  Object.entries(obj).forEach(([key, func]) => { // eslint-disable-line
    if (typeof func !== 'function') return apiProxy(func, `${path}${path ? '.' : ''}${key}`); // func is not a function, deep recursive
    obj[key] = async (...args) => { // eslint-disable-line
      try {
        const result = await func(...args);
        if (process.env.NODE_ENV === 'development') {
          // console.log(`API ${path}${path ? '.' : ''}${key}, METHOD: ${result.config.method}, STATUS: ${result.status}, RESULT:`, result);
        }
        return result.data;
      } catch (err) {
        if (process.env.NODE_ENV === 'development') {
          // TODO: uniform error
          if (err.response) {
            console.error(`API ${path}${path ? '.' : ''}${key}, METHOD: ${err.response.config.method}, STATUS: ${err.response.status}, ERROR:`, err);
          } else if (err.request) {
            console.error(`API ${path}${path ? '.' : ''}${key}, EMPTY_RESPONSE, ERROR:`, err);
          } else {
            console.error(`API ${path}${path ? '.' : ''}${key}, CONFIG_ERROR, ERROR:`, err);
          }
        }
        throw err;
      }
    };
  });
  return api;
};

export default apiProxy(api);
