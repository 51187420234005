import { defineStore } from 'pinia';
// import api from '@/services/api';
import { mapArrayToObjectsByKey } from '@/helpers/functions';
import { mapWebsite } from '@/helpers/website';
import { useLandingPagesStore } from './landingPages';

export const useWebsitesStore = defineStore('websites', {
  state: () => ({
    websites: [],
    loaded: false,
  }),
  getters: {
    websitesById: (state) => mapArrayToObjectsByKey(state.websites, 'id'),
    websiteLandersById: (state) => {
      const landingPagesStore = useLandingPagesStore();
      const websites = {};
      Object.keys(state.websitesById).forEach((key) => { websites[key] = []; });
      landingPagesStore.landers.forEach((lander) => {
        if (lander.websiteId && websites[lander.websiteId]) websites[lander.websiteId].push(lander);
      });
      return websites;
    }
  },
  actions: {
    async getWebsites() {
      // if (!this.loaded) {
      //   try {
      //     const { websites } = await api.websites.getAll();
      //     this.websites = websites.map((website) => mapWebsite(website));
      //     this.loaded = true;
      //   } catch (err) {
      //     console.log(err);
      //   }
      // }
    },
    async createWebsite(payload) {
      this.websites.unshift(mapWebsite(payload));
    },
    async updateWebsite(payload) {
      const index = this.websites.findIndex((item) => item.id === payload.id);
      if (index > -1) this.websites.splice(index, 1, mapWebsite(payload));
    },
    async deleteWebsite(id) {
      const index = this.websites.findIndex((item) => item.id === id);
      if (index > -1) this.websites.splice(index, 1);
    },
    clearStore() {
      this.$reset();
    },
  },
});
